/** Async promise that waits x amount in milleseconds */
export const wait = (dt: number) => new Promise(res => setTimeout(res, dt))

/**
 * Given a promise and a timelimit, wait for the promise to resolve until the timelimit.
 * If the promise takes longer than the provided time, it is resolved with "false".
 *
 * @param taskPromise the promise of the task
 * @param limit the timelimit in ms for the promise to resolve
 * @returns A promise that resolves to "false" if timeout or "T" if the task resolves within time
 */
export const resolveWithTimelimit = async <T>(
  taskPromise: () => Promise<T>,
  limit: number = 100,
) => {
  if (limit < 0) throw new Error('"limit" must be a positive value')

  const timeoutPromise = new Promise<false>(resolve => {
    setTimeout(() => {
      return resolve(false)
    }, limit)
  })

  const response = Promise.race<Promise<T> | Promise<false>>([
    taskPromise(),
    timeoutPromise,
  ])

  return response
}

/**
 * Call an assertion function n times with a optional delay in between.
 *
 * @param config.tries a number bigger than 0
 * @param config.delay a positive number (milleseconds)
 * @param config.assertFn a function that returns a boolean for the assertion result, can be a promise
 * @returns a boolean informing if the assertion was successful or not
 */
export const tryUntilTrue = async ({
  tries,
  delay = 0,
  assertFn,
}: {
  tries: number
  delay?: number
  assertFn: () => Promise<boolean> | boolean
}): Promise<boolean> => {
  if (tries <= 0) throw new Error('"tries" must be bigger than 0')

  if (delay < 0) throw new Error('"delay" must be a positive value')

  // Tries until assertion is true or max tries is reached.
  for (let t = tries; t > 0; t--) {
    const assertion = await assertFn()

    if (assertion) return true

    await wait(delay)
  }

  return false
}
